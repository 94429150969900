@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
}
html {
  width: 100%;
  height: 100%;
}
body > iframe {
  display: none;
}
.mySwiper {
  border-radius: 2rem 2rem 0rem 0rem;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-table-tbody > tr > td {
  font-family: "Poppins";
  color: rgb(30, 41, 49);
}
.ant-table-thead > tr > th {
  font-family: "Poppins";
  color: rgb(30, 41, 49);
  letter-spacing: 1.1px;
}
.switch {
  --secondary-container: #27284a;
  --primary: #fbfbfbf4;
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 4.5em;
  height: 2.5em;
}

.switch input {
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fbfbfbf4;
  backdrop-filter: blur(30px);
  border: 0.08px solid #27284a5f;
  transition: 0.2s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 2em;
  width: 2em;
  border-radius: 20px;
  left: 0.18em;
  bottom: 0.22em;
  background-color: #27284a;
  background-image: url(./assets/table.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
  transition: 0.4s;
}

input:checked + .slider::before {
  background-color: var(--primary);
  background-image: url(./assets/file.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
}

input:checked + .slider {
  background-color: var(--secondary-container);
  border: none;
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--secondary-container);
}

input:checked + .slider:before {
  transform: translateX(1.9em);
}

input[type="file"]::file-selector-button {
  border: 2px solid #7f6ff8bd;
  padding: 0.5em 1.2em;
  border-radius: 0.8rem;
  background-color: #a29bfe14;
  transition: 1s;
  color: whitesmoke;
  margin-right: 10px;
  cursor: pointer;
}
.filterDropDown::placeholder{
  color: white;
}